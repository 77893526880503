import React, { useState, useRef } from 'react'
import { sendQuery } from '../services/apiServices';

const emptyContact = {
    name: "",
    email: "",
    phone: "",
    description: "",
    subject: "Feature Request",
    type: "Feature Request",
}

function ContactForm() {
    const formRef = useRef();
    const [contact, setcontact] = useState(emptyContact);
    const [success, setsuccess] = useState(false);
    const [error, seterror] = useState(false);
    const handleChange = (e) => {
        let c = contact;
        c[e.target.id] = e.target.value;
        setcontact({ ...c });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(!error) seterror(false);
        if(!success) setsuccess(false);
        let res = await sendQuery(contact);
        if(res.statusCode === 200){
            formRef.current.reset();
            setsuccess(true)
        }
        else {
            seterror(true);
        }
    }

    return (
        <div className="w-full flex items-center flex-col">
            <h1 className="text-2xl text-blue-800 my-6">Contact Us</h1>
            <form className="bg-white shadow-xl rounded px-8 pt-6 pb-8 mb-4 w-11/12 md:w-1/2" onSubmit={handleSubmit} ref={formRef}>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                        Name
                    </label>
                    <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="name" type="text" placeholder="Name" onChange={handleChange} required/>
                </div>
                <div className="mb-6">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                        Email
                    </label>
                    <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="email" type="email" placeholder="Email" onChange={handleChange} required/>
                    {/* <p className="text-red-500 text-xs italic">Please choose a password.</p> */}
                </div>
                <div className="mb-6">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="phoneNumber">
                        PhoneNumber
                    </label>
                    <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="phone" type="number" placeholder="PhoneNumber" onChange={handleChange} required onWheel={(e) => e.target.blur()}/>
                    {/* <p className="text-red-500 text-xs italic">Please choose a password.</p> */}
                </div>
                <div className="mb-6">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="message">
                        Message
                    </label>
                    <textarea className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" rows="4" id="description" type="text" placeholder="Message" onChange={handleChange} required/>
                    {/* <p className="text-red-500 text-xs italic">Please choose a password.</p> */}
                </div>
                <div className="flex items-center justify-between">
                    <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                        Submit
                    </button>
                    
                </div>
                {success && <p className="text-green-700 text-base italic my-4">Thanks! Support team will contact your soon.</p>}
                {error && <p className="text-red-500 text-base italic my-4">Please wait at least 24 hours before raising another ticket.</p>}
            </form>

        </div>
    )
}

export default ContactForm
